<div class="main-menu">
    <div class="menu-content">
        <ng-content></ng-content>
        <div style="position: relative;padding: 0px 40px;">
            <div>
                <button class="button-85" role="button" onclick="window.location = '/main'">Main</button>
            </div>
        </div>
        <div class="menu">
            <button class="button-85" role="button" onclick="window.location = '/tcg/character'">Character</button>

            <button class="button-85" role="button" onclick="window.location = '/employee'">Employee</button>

            <button class="button-85" role="button" onclick="window.location = '/character_event'">
                Character Event
            </button>

            <button class="button-85" role="button" onclick="window.location = '/tcg'">
                TCG
            </button>


            <button class="button-85" role="button" onclick="window.location = '/color'">RGB</button>


            <button class="button-85" role="button" onclick="window.location = '/login'" *ngIf="!user">Login</button>

            <button class="button-85" role="button" (click)="logout()" *ngIf="user">Logout</button>
        </div>
    </div>
    <div style="height: 10px;">
        <app-color-rgb [type]="'wave'"></app-color-rgb>
    </div>
</div>