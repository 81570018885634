import { Component, ElementRef, Inject, Input, OnInit, HostListener } from '@angular/core';
import { LoginService } from '../../service/Login/login.service'
import { DOCUMENT } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { Message } from 'src/app/model/home-message';
import { map } from 'rxjs';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  elementRef: ElementRef;
  private basePath = '/home';
  nameMenu = 'Default';
  menu: any[] | undefined;
  displayNewFolder = false;
  @Input() fileUpload: Message | undefined;

  addMass = false;

  list: number[] = [-1];
  listHeight: number[] = [-1];
  maxHeight = 0;
  collection: any;

  fileUploads: any[] | undefined;
  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage, @Inject(ElementRef) elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {
    this.elementRef = elementRef;
  }
  updateMyDiv() {
    this.list = [];
    this.listHeight = [-1];
    this.maxHeight = 0;
    this.collection = this.elementRef.nativeElement.querySelectorAll('.mydiv');
    for (let i = 0; i < this.collection.length; i++) {
      this.list.push(i);
      this.dragElement(this.collection[i], i);
      this.collection[i].style.position = 'absolute';
      this.listHeight.push(this.collection[i].offsetHeight);
      this.collection[i].style.top = this.maxHeight + "px";
      this.maxHeight += this.collection[i].offsetHeight;
      this.collection[i].style.zIndex = 0;
      this.elementRef.nativeElement.querySelector("#heightMydiv").style.height = this.maxHeight + 'px';
      this.elementRef.nativeElement.querySelectorAll(".mydivheader")[i].style.backgroundColor = "rgba(0,0,0,0)";
      this.elementRef.nativeElement.querySelectorAll(".mydivheader")[i].style.color = "rgba(0,0,0,0)";
      this.elementRef.nativeElement.querySelectorAll(".mydiv")[i].addEventListener("mouseover", () => { this.hideHeader(i) });
      this.elementRef.nativeElement.querySelectorAll(".mydiv")[i].addEventListener("mouseout", () => { this.outHeader(i) });
    }
  }

  ngOnInit(): void {
    //get menu
    this.db.list('/home').snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key }))
      )
    ).subscribe(fileUploads => {
      this.menu = fileUploads;
    });
    //get Data
    this.getFileUploads(99).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(fileUploads => {
      this.fileUploads = fileUploads.reverse();
      this.fileUploads.sort((a, b) => b.sort - a.sort);
      setTimeout(() => { this.updateMyDiv(); }, 10);
    });


    let day = 6;
    let month = 9;
    let year = 2000;
    const bd = new Date(year, month - 1, day);
    const date = new Date();
    const diffTime = Math.abs(bd.getTime() - date.getTime());
    //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const dateBD = new Date(diffTime);
    const yearBD = (dateBD.getFullYear() - 1970);
    const monthBD = (dateBD.getMonth());
    const dayBD = (dateBD.getDate());
    //this.elementRef.nativeElement.querySelector('#date-now').innerHTML = Math.floor(diffDays/365)+ ' ปี : ' + Math.floor((diffDays%365)/30) + " เดือน : " + (diffDays%365)%30 + " วัน ";
    this.elementRef.nativeElement.querySelector('#date-now').innerHTML = yearBD + ' ปี : ' + monthBD + " เดือน : " + dayBD + " วัน ";
  }

  getFileUploads(numberItems: number): AngularFireList<Message> {
    if (this.nameMenu.trim() == "Default") {
      this.basePath = '/home/Default';
      return this.db.list('/home/Default', ref =>
        ref.limitToLast(numberItems).orderByChild('sort'));
    }
    this.basePath = '/home/' + this.nameMenu;
    return this.db.list('/home/' + this.nameMenu, ref =>
      ref.limitToLast(numberItems).orderByChild('sort'));
  }

  file: FileNote = new FileNote();

  saveFileData() {
    if (this.file.title.trim() != "" && this.displayNewFolder) {
      this.fileUpload = new Message(0, this.file.note);
      this.db.list('/home/' + this.file.title).push(this.fileUpload);
      this.file.note = "";
      this.addMass = false;

      this.nameMenu = this.file.title;
      this.ngOnInit()
    } else {
      if (this.fileUploads) {
        this.fileUpload = new Message(this.fileUploads.length, this.file.note);
      } else {
        this.fileUpload = new Message(0, this.file.note);
      }
      this.db.list(this.basePath).push(this.fileUpload);
      this.file.note = "";
      this.addMass = false;
    }
  }

  chengeMenu(key: string) {
    this.nameMenu = key;
    this.file.title = '';
    this.displayNewFolder = false;
    this.ngOnInit()
  }

  alertRemove(file: any) {
    this.elementRef.nativeElement.querySelector('#notify-title').innerHTML = 'คุณต้องการลบข้อมูลนี้หรือไม่\n ' + file.note;
    this.elementRef.nativeElement.querySelector('#boxalert').style.display = 'block';
    this.elementRef.nativeElement.querySelector('#items > button[type="submit"]').addEventListener("click", () => { this.removeFile(file) });
    //document.getElementById('boxalert').style.display='none';
  }

  removeFile(file: any) {
    if (this.fileUploads) {
      for (let mas of this.fileUploads) {
        if (mas.sort > file.sort) {
          this.db.list(this.basePath).update(mas.key, { sort: `${(mas.sort - 1)}` })
        }
      }
      this.db.list(this.basePath).remove(file.key);
      this.elementRef.nativeElement.querySelector('#boxalert').style.display = 'none';
    }
  }

  sortFileNumber() {
    if (this.fileUploads) {
      const up = [];
      for (let mass of this.fileUploads) {
        up.push(mass.key)
      }
      for (let i = 0; i < this.list.length; i++) {
        this.db.list(this.basePath).update(up[i], { sort: `${(this.list.length - 1 - this.list[i])}` });
      }
    }
  }

  outHeader(i: number) {
    this.elementRef.nativeElement.querySelectorAll(".mydivheader")[i].style.display = 'none';
    //this.elementRef.nativeElement.querySelectorAll(".mydivheader")[i].style.backgroundColor = "rgba(0,0,0,0)";
    //this.elementRef.nativeElement.querySelectorAll(".mydivheader")[i].style.color = "rgba(0,0,0,0)";
  }
  hideHeader(i: number) {
    this.elementRef.nativeElement.querySelectorAll(".mydivheader")[i].style.display = 'flex';
    this.elementRef.nativeElement.querySelectorAll(".mydivheader")[i].style.backgroundColor = "#2196F3";
    this.elementRef.nativeElement.querySelectorAll(".mydivheader")[i].style.color = "#fff";
  }

  @HostListener("mousemove click", ["$event"])
  dragElement(elmnt: { className: string; onmousedown: { (e: any): void; (e: any): void; (e: any): void; }; style: { zIndex: number; top: string; left: number; }; offsetTop: number; offsetHeight: number; }, n: number) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    var pos = -1;
    var list = this.list;
    var collection = this.collection;
    var listHeight = this.listHeight;
    //var test = this.elementRef.nativeElement.querySelector("#test");
    if (this.elementRef.nativeElement.querySelectorAll("." + elmnt.className + "header")[n]) {
      this.elementRef.nativeElement.querySelectorAll("." + elmnt.className + "header")[n].onmousedown = dragMouseDown;
    } else {
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e: MouseEvent) {
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e: MouseEvent) {
      e.preventDefault();
      elmnt.style.zIndex = 1;
      let prev = -1;
      let next = -1;
      for (let i = 0; i < list.length; i++) {
        if (list[n] - 1 == list[i]) {
          prev = i;
        }
        if (list[n] + 1 == list[i]) {
          next = i;
        }
      }

      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      // set the element's new position:
      if ((elmnt.offsetTop - pos2) < 0) {
        elmnt.style.top = 0 + "px";
        elmnt.onmousedown = dragMouseDown;
      }
      if ((elmnt.offsetTop - pos2) > (listHeight.reduce((a: any, b: any) => a + b, 0) - elmnt.offsetHeight)) {
        elmnt.style.top = (listHeight.reduce((a: any, b: any) => a + b, 0) - elmnt.offsetHeight) + "px";
        elmnt.onmousedown = dragMouseDown;
      }

      //let pos = 0;
      pos = 0;
      if (collection[prev]) {
        pos = collection[prev].offsetTop + collection[prev].offsetHeight;
      }
      if (collection[next] && pos + (collection[next].offsetHeight / 2) < (elmnt.offsetTop - pos2)) {
        collection[next].style.top = pos + "px";
        let j = list[next];
        list[next] = list[n];
        list[n] = j;
      } else if (collection[prev] && pos - (collection[prev].offsetHeight / 2) > (elmnt.offsetTop - pos2)) {
        collection[prev].style.top = (pos - (collection[prev].offsetHeight - elmnt.offsetHeight)) + "px";
        let j = list[prev];
        list[prev] = list[n];
        list[n] = j;
      } else {
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      }
      //elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      //elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      elmnt.style.left = 0;
      //listHeight.reduce((a, b) => a + b, 0)
      //test.innerHTML = collection[prev].offsetHeight-elmnt.offsetHeight//pos - (elmnt.offsetHeight/2)//(elmnt.offsetTop - pos2) + "px";

    }

    const closeDragElement = () => {
      elmnt.style.zIndex = 0;
      document.onmouseup = null;
      document.onmousemove = null;
      if (pos != -1) {
        setTimeout(function () {
          elmnt.style.top = pos + "px";
        }, 50);
      }
      this.sortFileNumber();
    }
  }

  auto_grow(element: any) {
    element.target.style.height = "auto";
    element.target.style.height = (element.target.scrollHeight) + "px";
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  selectCopyText(note: string, n: number) {
    navigator.clipboard.writeText(note);
    if (this.selection) {
      this.selection.view.getSelection().removeAllRanges();
    }

    if (this.elementRef.nativeElement.querySelectorAll(".copys")[n].classList.toString().indexOf("active") == -1) {
      this.elementRef.nativeElement.querySelectorAll(".copys")[n].classList.add("active");
      setTimeout(() => {
        this.elementRef.nativeElement.querySelectorAll(".copys")[n].classList.remove("active");
      },
        2000);
    }
    //alert('U selected text');
    //document.
  }
  selection: any;
  @HostListener('click', ['$event'])
  public onClick(event: any) {
    if (event.view.getSelection().type !== 'Range') {
      //alert('you clicked');
    } else {
      this.selection = event;
      //alert('U selected text');
      return;
    }
  }
  check = false;
  ngAfterViewChecked() {
    if (!this.check) {
      let menu = this.elementRef.nativeElement.querySelector('#menu');
      let width = menu.offsetWidth;
      menu.style.left = '-' + width + 'px';
      this.check = true;
    }
  }


  showMenu() {
    let menu = this.elementRef.nativeElement.querySelector('#menu');
    let width = menu.offsetWidth;
    let left = menu.style.left;
    if (left !== '0px') {
      menu.style.left = '0px';
    } else {
      menu.style.left = '-' + width + 'px';
    }
  }

}
class FileNote {
  sort = 0;
  title = "";
  note = "";
}