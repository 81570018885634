import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { Message } from '../model/home-message';

@Component({
  selector: 'app-webhook-github',
  templateUrl: './webhook-github.component.html',
  styleUrls: ['./webhook-github.component.css']
})
export class WebhookGithubComponent implements OnInit {

  guildId: string | undefined;
  channelId: string | undefined;

  constructor(private route: ActivatedRoute, private http: HttpClient
    , private db: AngularFireDatabase, private storage: AngularFireStorage, @Inject(ElementRef) elementRef: ElementRef, @Inject(DOCUMENT) private document: Document
  ) {
    this.elementRef = elementRef;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.guildId = params['guildId'];
      this.channelId = params['channelId'];
    });

    //get Data
    this.getFileUploads(99).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe((fileUploads: any[]) => {
      this.fileUploads = fileUploads.reverse();
    });
  }

  elementRef: ElementRef;
  private basePath = '/home';
  nameMenu = 'Default';
  menu: any[] | undefined;
  displayNewFolder = false;

  addMass = false;

  list: number[] = [-1];
  listHeight: number[] = [-1];
  maxHeight = 0;
  collection: any;

  fileUploads: any[] | undefined;

  getFileUploads(numberItems: number): AngularFireList<Message> {
    if (this.nameMenu.trim() == "Default") {
      this.basePath = '/home/Default';
      return this.db.list('/home/Default', ref =>
        ref.limitToLast(numberItems).orderByChild('sort'));
    }
    this.basePath = '/home/' + this.nameMenu;
    return this.db.list('/home/' + this.nameMenu, ref =>
      ref.limitToLast(numberItems).orderByChild('sort'));
  }

  file: FileNote = new FileNote();

  saveFileData() {
    this.db.list('/home/' + this.file.title).push(this.fileUploads);
  }

}
class FileNote {
  sort = 0;
  title = "";
  note = "";
}