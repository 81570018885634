import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { map } from 'rxjs';

@Component({
  selector: 'app-edit-artifact-live',
  templateUrl: './edit-artifact-live.component.html',
  styleUrls: ['./edit-artifact-live.component.css']
})
export class EditArtifactLiveComponent implements OnInit {


  elementRef: ElementRef;
  private basePath = '/tiktok/artifact';

  fileUploads: any[] | undefined;

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage, @Inject(ElementRef) elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    this.getFileUploads().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe((fileUploads: any[]) => {
      if (this.fileUploads != fileUploads) {
        this.fileUploads = fileUploads;
        this.fileUploads.sort((a, b) => a.status - b.status);
      }
    });
  }

  file: FileNote = new FileNote();

  getFileUploads(): AngularFireList<FileNote> {
    return this.db.list(this.basePath, ref =>
      ref.orderByChild('date'));
  }

  checkFileData() {
    let startDate = this.fileUploads?.find((item) => item.date != null && item.date !== '' && item.key == this.file.uid);
    if (startDate != undefined) {
      let date_now = new Date();
      let count_date = +date_now.getTime() - +startDate.date //+ 86400000;
      let date = Math.floor(count_date / 1000 / 60 / 60 / 24);
      if (date >= 2) {
        return true;
      } else {
        alert(`เคยบันทึกแล้วเป็นเวลา ${date}วัน ต้องเว้นเวลาอย่างน้อย 2 วัน`);
        return false;
      }
    } else {
      return true;
    }
  }

  saveFileData() {
    if (this.file.uid != '' && this.file.note != '' && this.checkFileData()) {
      this.file.date = new Date().getTime();
      this.db.list(this.basePath).update(this.file.uid.trim(), this.file);
      this.file = new FileNote();
    }
  }

}
class FileNote {
  uid = "";
  note = "";
  status = 1;
  date = 0;
}