<div class="main-profile">
    <div class="profile">
        <img jsname="OX8uie"
            src="https://lh3.googleusercontent.com/a/ACg8ocJHRZwUx1lk52ZLkjA0BR0j2m3mntvR95iPcC94vxHxq7wjei4D=s288-c-no"
            alt="รูปโปรไฟล์ของคุณ" class="EEKrSc xUNOSc" jsaction="click:pLamw" data-iml="4447.299999998882"
            jslog="95413; track:click,impression"
            style="border-radius: 50%;border: 6px solid rgb(4, 42, 106);margin-top: 0.5in;">
        <button class="button-85" role="button" style="height: fit-content;">SKILLS AND ABILITIES</button>
        <ul style="color: #fff;display: grid;grid-template-columns: 1fr 1fr;">
            <li>Java</li>
            <li>JavaScript</li>
            <li>Angular</li>
            <li>Spring Boot</li>
            <li>Hibernate</li>
            <li>MySQL</li>
            <li>Firebase</li>
            <li>Oracle</li>
            <li>Node.js</li>
        </ul>
        <button class="button-85" role="button" style="height: fit-content;">SUBJECTS STUDIED</button>
        <ul style="color: #fff;">
            <li>Server Side Programming</li>
            <li>Object Oriented Development<br> with UML</li>
        </ul>
        <button class="button-85" role="button" style="height: fit-content;">CONTRACT</button>
        <ul style="color: #fff;">
            <li>0958805467</li>
            <li>puttipong.chomchit@gmail.com</li>
            <li>
                91/67 Moo 9, Ton Pao Sub-district,<br>
                San Kamphaeng District,<br>
                Chiang Mai 50130 Thailand
            </li>
        </ul>
    </div>

    <div class="head-name">
        <div class="head-bg">

        </div>
        <div class="fullname">
            PUTTIPONG CHOMCHIT
        </div>
        <div class="fullname" style="font-size: 35px;">
            Software Developer
        </div>
    </div>
    <div class="about-me">
        <div class="head">
            <div class="title">
                ABOUT ME
            </div>
            <div class="detail">
                I am interested in working as a Software Developer<br>
                because I have the programmingknowledge to<br>
                development websites with Java,HTML and CSS.<br>
                Which I blieved to be used as abesic for working and I<br>
                can be extended in thefuture. I also have good human<br>
                relation. Able towork with others, be diligent, patient,<br>
                takeresponsibility for the work received and listen<br>
                inthe opinions of others. Ialways develop myself.
            </div>
        </div>
        <div class="head">
            <div class="title">
                PERFORMANCE
            </div>
            <div class="detail">
                Senior Project | 2021-2022<br><br>
                Scholarship Fund Donation System for Sciences,<br>
                Maejo University
            </div>
        </div>
        <div class="head">
            <div class="title">EDUCATION</div>
            <div class="detail">
                <b>Maejo University</b><br>
                Information Technology<br><br>
                Maejo University,Chiang Mai Faculty of Science<br>
                Bachelor's degree in 2018 - 2023
            </div>
        </div>
        <div class="head">
            <div class="title">UNIQUE SKILLS</div>
            <div class="detail">
                <ul>
                    <li>Polite</li>
                    <li>Works well with others</li>
                    <li>Adapts quickly to various situations</li>
                </ul>
            </div>
        </div>
        <div class="head">
            <div class="title">HOBBIES</div>
            <div class="detail">
                <ul>
                    <li>Exercising</li>
                    <li>Drawing</li>
                    <li>Cooking</li>
                </ul>
            </div>
        </div>
    </div>
</div>