import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-fn-projects',
  templateUrl: './fn-projects.component.html',
  styleUrls: ['./fn-projects.component.css']
})
export class FnProjectsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  orderObj: any;

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params) => {
        this.orderObj = { ...params.keys, ...params };
      }
      );
  }

}
