import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { map } from 'rxjs';
import { LoginService } from '../../service/Login/login.service'
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-artifact-live',
  templateUrl: './artifact-live.component.html',
  styleUrls: ['./artifact-live.component.css']
})
export class ArtifactLiveComponent implements OnInit {

  elementRef: ElementRef;
  private basePath = '/tiktok/artifact';

  fileUploads: any[] | undefined;
  user: any | undefined;

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage, @Inject(ElementRef) elementRef: ElementRef,
    private auth: AngularFireAuth) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    this.getFileUploads().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe((fileUploads: any[]) => {
      if (this.fileUploads != fileUploads) {
        this.fileUploads = fileUploads;
        this.fileUploads = fileUploads.filter((item) => {
          if (item.status != 3) {
            return item;
          }
        })
        this.fileUploads.sort((a, b) => a.status - b.status);
      }
    });
    this.auth.user.subscribe(userInfo => {
      this.user = userInfo?.uid;
    })
    //this.getFile();
  }

  getFile() {
    this.getFileUploads().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe((fileUploads: any[]) => {
      if (this.fileUploads != fileUploads) {
        this.fileUploads = fileUploads;
        this.fileUploads.sort((a, b) => a.status - b.status);
      }
    });
    /*
    setTimeout(() => {
      this.getFile();
    }, 1000)
    */
  }
  getFileUploads(): AngularFireList<FileNote> {
    return this.db.list(this.basePath, ref =>
      ref.orderByChild('date'));
  }
  /*
  getFileUploads(numberItems: number): AngularFireList<FileNote> {
    return this.db.list(this.basePath, ref =>
      ref.limitToLast(numberItems).orderByChild('date'));
  }
  */
  file: FileNote = new FileNote();

  skipArtifact(key: any) {
    this.db.list(this.basePath).update(key, { status: 2 })
  }

  playArtifact(key: any) {
    this.db.list(this.basePath).update(key, { status: 1 })
  }

  acceptArtifact(key: any) {
    this.db.list(this.basePath).update(key, { status: 3 })
  }

  stypeColor() {

  }

}
class FileNote {
  uid = "";
  note = "";
  status = 1;
}