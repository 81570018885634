import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database'
import { DatabaseReference } from '@angular/fire/compat/database/interfaces';
import { map } from 'rxjs';
@Component({
  selector: 'app-tiktok-notify',
  templateUrl: './tiktok-notify.component.html',
  styleUrls: ['./tiktok-notify.component.css']
})
export class TiktokNotifyComponent implements OnInit {
  pathDB = "/notify/key/overview";
  pathOnlineDB = "/notify/key/online";
  constructor(private db: AngularFireDatabase) { }
  //action: any;
  list: any;
  count = 0;
  playing = false;
  g_playing = false;
  status: any;
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;//, { static: false }
  @ViewChild('audioSpeek') audioSpeek!: ElementRef<HTMLAudioElement>;
  async ngOnInit(): Promise<void> {
    await this.getFileUploads(this.pathOnlineDB).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(result => {
      if (!this.key && this.count <= 0) {
        this.db.list(this.pathOnlineDB).remove();
        this.updateOnlineStatus();

      } else if (this.key) {
        let list_play = result.filter(item => item.status == 'playing');
        if (list_play.length !== 0) {
          this.g_playing = true;
        } else {
          this.g_playing = false;
        }

        let online = result.filter(item => item.key === this.key);
        if (online.length === 0) {
          let status;
          if (this.playing) {
            status = 'playing';
          } else {
            status = 'pause';
          }
          this.db.list(this.pathOnlineDB).update(this.key, { status: status });
        }
      }
      this.status = result;
    });

    await this.getFileUploads(this.pathDB).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(async result => {
      console.log(this.status.length);

      this.list = result.filter((item: any) => !item.played);

      if (this.status.length == 0 && this.list.length != 0) {
        this.list.forEach((element: {
          key: any, played: any;
        }) => {
          this.db.list(this.pathDB).update(element.key, { played: true })
        });
      }

      if (this.count > 0 && !this.playing && !this.g_playing && this.list.length != 0) {
        this.playAudio(this.list);
      }

      this.count++;
    });

    // const key = 'test';  // กำหนดค่า key ที่คุณต้องการดึง

    // this.getDataByKey(this.pathDB, key).valueChanges().subscribe(data => {
    //   if (data) {
    //     this.action = JSON.stringify(data)
    //     this.playAudio()
    //   }
    // });

  }
  /*
  getDataByKey(path: string, key: string): AngularFireObject<any> {
    return this.db.object(`${path}/${key}`);
  }
  */
  getFileUploads(path: string): AngularFireList<any> {
    return this.db.list(path, ref =>
      ref.orderByChild('date'));
  }

  pathVideo = "../../../assets/video/";
  playAudio(item: any) {
    //this.db.list(this.pathDB).push({ id: 0, date: 0 })

    if (item[0].video != "") {
      //this.videoPlayer.nativeElement.style.display = 'block';
      this.videoPlayer.nativeElement.src = `${this.pathVideo}${item[0].video}`;
      this.videoPlayer.nativeElement.volume = item[0].volume;
      this.videoPlayer.nativeElement.play();
    }

    if (item[0].music != "") {
      this.audioPlayer.nativeElement.src = `${this.pathVideo}${item[0].music}`;
      this.audioPlayer.nativeElement.play();
    }
    this.playing = true;
    if (this.key) this.db.list(this.pathOnlineDB).update(this.key, { status: 'playing' });

    setTimeout(() => {
      this.playing = false;
      if (this.key) this.db.list(this.pathOnlineDB).update(this.key, { status: 'pause' });
    }, (item[0].timeout - 1000));
    setTimeout(() => {
      this.videoPlayer.nativeElement.pause();
      this.audioPlayer.nativeElement.pause();
      this.db.list(this.pathDB).update(item[0].key, { played: true })

      // if (this.list.length != 0) {
      //   this.playAudio(this.list);
      // }
      //this.db.list(this.pathDB).remove(this.list[0].key);
    }, item[0].timeout);
    if (item[0].text) this.speek(item[0].text);
  }

  onVideoPlaying() {

  }

  addPlay() {
    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณโมะ",
    //   amount: 200,
    //   video: "1_1.mp4",
    //   music: "",
    //   timeout: 30000,
    //   volume: 1.0,
    //   date: Date.now()
    // }

    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณโมะ",
    //   amount: 200,
    //   video: "1_2.mp4",
    //   music: "",
    //   timeout: 30000,
    //   volume: 1.0,
    //   date: Date.now()
    // }

    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณโมะ",
    //   amount: 200,
    //   video: "2_1.mp4",
    //   music: "",
    //   timeout: 35000,
    //   volume: 0.7,
    //   date: Date.now()
    // }

    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณโมะ",
    //   amount: 200,
    //   video: "2_2.mp4",
    //   music: "",
    //   timeout: 35000,
    //   volume: 0.7,
    //   date: Date.now()
    // }

    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณโมะ",
    //   amount: 200,
    //   video: "3.mp4",
    //   music: "",
    //   timeout: 12500,
    //   volume: 0.7,
    //   date: Date.now()
    // }

    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณ Risu",
    //   amount: 200,
    //   video: "4.mp4",
    //   music: "",
    //   timeout: 12500,
    //   volume: 1.0,
    //   date: Date.now()
    // }

    let item = {
      //name: "Risu",
      //text: "สวัสดีครับคุณ Risu",
      //amount: 200,
      video: "5.mp4",
      music: "",
      timeout: 17000,
      volume: 1.0,
      date: Date.now()
    }

    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณ Risu",
    //   amount: 200,
    //   video: "6.mp4",
    //   music: "",
    //   timeout: 30000,
    //   volume: 1.0,
    //   date: Date.now()
    // }

    // this.db.list(this.pathDB).push(item);

    // item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณโมะ",
    //   amount: 200,
    //   video: "7.mp4",
    //   music: "",
    //   timeout: 10000,
    //   volume: 0.7,
    //   date: Date.now()
    // }

    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณโมะ",
    //   amount: 200,
    //   video: "7.mp4",
    //   music: "",
    //   timeout: 10000,
    //   volume: 0.7,
    //   date: Date.now()
    // }

    // let item = {
    //   name: "Risu",
    //   text: "สวัสดีครับคุณโมะ",
    //   amount: 200,
    //   video: "n1.mp4",
    //   music: "mitake_v2.mp3",
    //   timeout: 17000,
    //   volume: 1.0,
    //   date: Date.now()
    // }


    this.db.list(this.pathDB).push(item);
  }

  // onAudioEnded() {
  //   //alert('The audio has finished playing!');
  //   this.playing = false;
  //   //alert(this.list[0].key)
  //   this.videoPlayer.nativeElement.style.display = 'none';
  //   this.db.list(this.pathDB).remove(this.list[0].key);

  //   // You can perform any additional actions here
  // }

  // onAudioPlaying() {
  //   let isPaused = this.audioPlayer.nativeElement.paused;
  //   if (!isPaused) {
  //     this.playing = true;
  //   }
  // }

  speek(text: string) {
    setTimeout(() => {
      let take = `https://www.google.com/speech-api/v2/synthesize?key=AIzaSyBOti4mM-6x9WDnZIjIeyEU21OpBXqWBgw&enc=mpeg&lang=th-TH&text=${text}&speed=0.50&pitch=0.50&rate=48000&gender=female`
      this.audioSpeek.nativeElement.src = take;
      this.audioSpeek.nativeElement.play();
    }, 1700)
  }

  key: string | null = null; // Store the key for the pushed item

  // Detect when user goes online/offline and handle accordingly
  @HostListener('window:online')
  updateOnlineStatus() {
    if (navigator.onLine) {
      // User is online, push new data and store the key
      const ref: DatabaseReference = this.db.list(this.pathOnlineDB).push({ status: 'pause' });
      this.key = ref.key; // Store the key to remove it later when the browser closes
    }
  }

  // Detect when browser is about to close or reload
  @HostListener('window:beforeunload')
  onBrowserClose() {
    if (this.key) {
      // Remove the entry from Firebase when browser is closing
      let key_remove = this.key;
      this.key = null;
      this.db.list(this.pathOnlineDB).remove(key_remove);
    }
  }


}