import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-character-tcg',
  templateUrl: './character-tcg.component.html',
  styleUrls: ['./character-tcg.component.css']
})
export class CharacterTcgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
