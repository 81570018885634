import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-character-event',
  templateUrl: './character-event.component.html',
  styleUrls: ['./character-event.component.css']
})
export class CharacterEventComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
