<!--div style="display: flex;">
    <video #videoPlayer height="405" loop (playing)="onVideoPlaying()">
        <source src="../../../assets/video/1_1.mp4" type="video/mp4">
        Your browser does not support the video tag.
    </video>
    <div style="padding-left: 20px;padding-right:200px;">
        <div style="display: flex;gap: 20px;font-weight: 600;text-shadow: #000 0 0 20px;font-size: 75px;">
            <p>Risu</p>
            <p style="color: whitesmoke;">โดเนทให้</p>
            <p>฿ 200</p>
        </div>
        <div style="font-size: 36px;color: #17A2B8;font-weight: 700;text-shadow: hsla(0, 0%, 0%, 0.7) 0 0 20px;">
            <p>sadasdtesrsadsad</p>
        </div>
    </div>
</div-->
<div style="display: flex;justify-content: center;opacity: 0;font-family: Prompt, sans-serif;"
    [ngClass]="{'fadeIn': playing && list?.length > 0 , 'fadeOut': !playing  && list?.length > 0 && (count > 1)}">
    <!--*ngIf="list.length > 0"-->
    <div class="notify">

        <video #videoPlayer height=" 405" loop (playing)="onVideoPlaying()"><!--controls style="display: none;"-->
            <source type="video/mp4">
            Your browser does not support the video tag.
        </video>

        <!--div style="padding-left: 20px;">
    <div style="display: flex;gap: 20px;font-weight: 700;">
        <p>{{list[0].name}} โดเนทให้</p>
        <p>฿ {{list[0].amount}}</p>
    </div>
    <div style="font-size: 36px;">
        <p>{{list[0].text}}</p>
    </div>
</div-->
        <div style="padding-left: 20px;padding-right:200px;" *ngIf="list?.length > 0 && list[0].name">
            <!--*ngIf="let item of list"-->
            <div style="display: flex;gap: 20px;font-weight: 600;text-shadow: #000 0 0 20px;">
                <p>{{list[0].name}}</p>
                <p style="color: whitesmoke;">โดเนทให้</p>
                <p>฿{{list[0].amount}}</p>
            </div>
            <div style="font-size: 36px;color: #17A2B8;font-weight: 700;text-shadow: hsla(0, 0%, 0%, 0.7) 0 0 20px;">
                <p>{{list[0].text}}</p>
            </div>
        </div>
    </div>
</div>
<audio #audioPlayer> <!-- (ended)="onAudioEnded()" (playing)="onAudioPlaying()"-->
    <source type="audio/mpeg">
    Your browser does not support the audio element.
</audio>

<audio #audioSpeek>
    <source type="audio/mpeg">
    Your browser does not support the audio element.
</audio>
<!--button (click)="playAudio()">Play</button-->
<!-- <div style="position: fixed;bottom: 0;height: 50vh;">
    <button (click)="addPlay()">Play</button>
    <button (click)="speek('สวัสดีครับ')">สวัสดีครับ</button>
</div> -->