export class Login {
    key!: string;
    name!: string;
    url!: string;

    username: string = '';
    password: string = '';

    constructor() {
    }

}