import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-discord-guilds',
  templateUrl: './discord-guilds.component.html',
  styleUrls: ['./discord-guilds.component.css']
})
export class DiscordGuildsComponent implements OnInit {
  guildData: any[] = [];
  channelData: any[] = [];

  selGuild = '';

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getGuildData().subscribe(
      (data: any[]) => {
        this.guildData = data;
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );
  }

  getGuildData(): Observable<any[]> {
    return this.http.get<any[]>('http://localhost:3000/bot/len/discord/guild');
  }

  getFileChannels(guilId: any, channels: any) {
    this.getChannelData(guilId, channels).subscribe(
      (data: any[]) => {
        this.channelData = data;
        return data;
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );

    return undefined;
  }

  getChannelData(guilId: any, channels: any): Observable<any[]> {
    return this.http.get<any[]>(`http://localhost:3000/bot/len/discord/${guilId}/${channels}`);
  }

}
