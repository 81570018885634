import { Component, ElementRef, Inject, OnInit, QueryList } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { map } from 'rxjs';
import { Router } from '@angular/router';
import { query, request } from 'express';

@Component({
  selector: 'app-fn-index',
  templateUrl: './fn-index.component.html',
  styleUrls: ['./fn-index.component.css']
})
export class FnIndexComponent implements OnInit {

  elementRef: ElementRef;
  private basePath = '/fn';
  fileUploads: any[] | undefined;
  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    @Inject(ElementRef) elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    this.getFileUploads(99).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(fileUploads => {
      this.fileUploads = fileUploads.reverse();
    });
  }

  getFileUploads(numberItems: number): AngularFireList<FN> {
    return this.db.list(this.basePath, ref =>
      ref.limitToLast(numberItems).orderByChild('sort'));
  }

  newProject() {
    this.db.list(this.basePath).push(new FN("new-project"));
  }

  delProject(key: any) {
    this.db.list(this.basePath).remove(key)
  }

  updateProject(file: any, x: any) {
    let name = x.target.value;
    if (x.keyCode == 13 && name.trim() != '') {
      file.select = false;
      this.db.list(this.basePath).update(file.key, { name: name })
        .finally(() => {
          file.select = false;
        });
    }
  }

  test(file: any, index: number) {
    file.select = true;
    this.elementRef.nativeElement.querySelector(`#test${index}`).focus();

  }

  openProject(file: any) {
    this.router.navigate(['/fn/projects'], { queryParams: { id: file.key } });
  }



}
class FN {
  name = "";
  constructor(name: string) {
    this.name = name;
  }
}