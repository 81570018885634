<app-fn-menu></app-fn-menu>
<style>

</style>
<div>
    <button (click)="newProject()">
        New Project
    </button>
</div>
<div>
    <table>
        <tr>
            <th>
                No.
            </th>
            <th>
                name
            </th>
            <th>
                action
            </th>
        </tr>
        <tr *ngFor="let file of fileUploads;let index = index">
            <td>
                {{index+1}}
            </td>
            <td *ngIf="!file.select">
                <span (click)="test(file,index)">{{file.name}}</span>
            </td>
            <td *ngIf="file.select">
                <input type="text" (keyup)="updateProject(file,$event)" (blur)="file.select = false" id="test{{index}}">
            </td>
            <td>
                <button (click)="openProject(file)">
                    Open
                </button>
                <button (click)="delProject(file.key)">
                    Delete
                </button>
            </td>
        </tr>
    </table>
</div>