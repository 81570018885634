<div class="main">
    <div class="container-login">
        <div class="screen">
            <div class="screen__content">
                <div class="login">
                    <div class="login__field">
                        <i class="login__icon fa fa-user"></i>
                        <input type="text" class="login__input" placeholder="User name / Email"
                            [(ngModel)]="file.username">
                    </div>
                    <div class="login__field">
                        <i class="login__icon fa fa-lock"></i>
                        <input type="password" class="login__input" placeholder="Password" [(ngModel)]="file.password">
                    </div>
                    <button class="button login__submit" (click)="login()">
                        <span class="button__text">Log In Now</span>
                        <i class="button__icon fa fa-chevron-right"></i>
                    </button>
                </div>
            </div>
            <div class="screen__background">
                <span class="screen__background__shape screen__background__shape4"></span>
                <span class="screen__background__shape screen__background__shape3"></span>
                <span class="screen__background__shape screen__background__shape2"></span>
                <span class="screen__background__shape screen__background__shape1"></span>
            </div>
        </div>
    </div>
</div>