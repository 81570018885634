<app-tcg-menu></app-tcg-menu>
<style>
    p {
        color: red;
    }

    li {
        font-size: 16px;
    }

    li:hover {
        color: blue;
        font-size: 18px;
        font-weight: 500;
    }
</style>
<div>
    <ul>
        <p>A</p>
        <li onclick="window.location = '/tcg/character/Abyss_Herald_Wicked_Torrents'">Abyss Herald Wicked Torrents</li>
        <li onclick="window.location = '/tcg/character/Abyss_Lector_Fathomless_Flames'">Abyss Lector - Fathomless Flames
        </li>
        <li onclick="window.location = '/tcg/character/Albedo'">Albedo</li>
        <li onclick="window.location = '/tcg/character/Alhaitham'">Alhaitham</li>
        <li onclick="window.location = '/tcg/character/Amber'">Amber</li>
        <li onclick="window.location = '/tcg/character/Arataki'">Arataki Itto</li>
        <li onclick="window.location = '/tcg/character/Azhdaha'">Azhdaha</li>
        <p>B</p>
        <li onclick="window.location = '/tcg/character/Baizhu'">Baizhu</li>
        <li onclick="window.location = '/tcg/character/Barbara'">Barbara</li>
        <li onclick="window.location = '/tcg/character/Beidou'">Beidou</li>
        <li onclick="window.location = '/tcg/character/Bennett'">Bennett</li>
        <p>C</p>
        <li onclick="window.location = '/tcg/character/Candace'">Candace</li>
        <li onclick="window.location = '/tcg/character/Charlotte'">Charlotte</li>
        <li onclick="window.location = '/tcg/character/Chongyun'">Chongyun</li>
        <li onclick="window.location = '/tcg/character/Collei'">Collei</li>
        <li onclick="window.location = '/tcg/character/Cryo_Hypostasis'">Cryo Hypostasis</li>
        <li onclick="window.location = '/tcg/character/Cyno'">Cyno</li>
        <p>D</p>
        <li onclick="window.location = '/tcg/character/Dehya'">Dehya</li>
        <li onclick="window.location = '/tcg/character/Diluc'">Diluc</li>
        <li onclick="window.location = '/tcg/character/Diona'">Diona</li>
        <li onclick="window.location = '/tcg/character/Dori'">Dori</li>
        <li onclick="window.location = '/tcg/character/Dvalin'">Dvalin</li>
        <p>E</p>
        <li onclick="window.location = '/tcg/character/Electro_Hypostasis'">Electro Hypostasis</li>
        <li onclick="window.location = '/tcg/character/Emperor_Of_Fire_And_Iron'">Emperor of Fire and Iron</li>
        <li onclick="window.location = '/tcg/character/Eremite_Scorching_Loremaster'">Eremite Scorching Loremaster</li>
        <li onclick="window.location = '/tcg/character/Eula'">Eula</li>
        <p>F</p>
        <li onclick="window.location = '/tcg/character/Faruzan'">Faruzan</li>
        <li onclick="window.location = '/tcg/character/Fatui_Cryo_Cicin_Mage'">Fatui - Cryo Cicin Mage</li>
        <li onclick="window.location = '/tcg/character/Fatui_Electro_Cicin_Mage'">Fatui - Electro Cicin Mage</li>
        <li onclick="window.location = '/tcg/character/Fatui_Pyro_Agent'">Fatui Pyro Agent</li>
        <li onclick="window.location = '/tcg/character/Fischl'">Fischl</li>
        <p>G</p>
        <li onclick="window.location = '/tcg/character/'">Ganyu</li>
        <li onclick="window.location = '/tcg/character/'">Gorou</li>
        <p>H</p>
        <li onclick="window.location = '/tcg/character/'">Hu Tao</li>
        <p>J</p>
        <li onclick="window.location = '/tcg/character/'">Jadeplume Terrorshroom</li>
        <li onclick="window.location = '/tcg/character/'">Jean</li>
        <p>K</p>
        <li onclick="window.location = '/tcg/character/'">Kaedehara Kazuha</li>
        <li onclick="window.location = '/tcg/character/'">Kaeya</li>
        <li onclick="window.location = '/tcg/character/'">Kamisato Ayaka</li>
        <li onclick="window.location = '/tcg/character/'">Kamisato Ayato</li>
        <li onclick="window.location = '/tcg/character/'">Keqing</li>
        <li onclick="window.location = '/tcg/character/'">Kirara</li>
        <li onclick="window.location = '/tcg/character/'">Klee</li>
        <li onclick="window.location = '/tcg/character/'">Kujou Sara</li>
        <li onclick="window.location = '/tcg/character/'">Kuki Shinobu</li>
        <p>L</p>
        <li onclick="window.location = '/tcg/character/'">La Signora</li>
        <li onclick="window.location = '/tcg/character/'">Layla</li>
        <li onclick="window.location = '/tcg/character/'">Lisa</li>
        <li onclick="window.location = '/tcg/character/'">Lynette</li>
        <li onclick="window.location = '/tcg/character/'">Lyney</li>
        <p>M</p>
        <li onclick="window.location = '/tcg/character/'">Maguu Kenki</li>
        <li onclick="window.location = '/tcg/character/'">Millennial Pearl Seahorse</li>
        <li onclick="window.location = '/tcg/character/'">Mirror Maiden</li>
        <li onclick="window.location = '/tcg/character/'">Mona</li>
        <p>N</p>
        <li onclick="window.location = '/tcg/character/'">Nahida</li>
        <li onclick="window.location = '/tcg/character/'">Neuvillette</li>
        <li onclick="window.location = '/tcg/character/'">Nilou</li>
        <li onclick="window.location = '/tcg/character/'">Ningguang</li>
        <li onclick="window.location = '/tcg/character/'">Noelle</li>
        <p>Q</p>
        <li onclick="window.location = '/tcg/character/'">Qiqi</li>
        <p>R</p>
        <li onclick="window.location = '/tcg/character/'">Raiden Shogun</li>
        <li onclick="window.location = '/tcg/character/'">Razor</li>
        <li onclick="window.location = '/tcg/character/'">Rhodeia of Loch</li>
        <p>S</p>
        <li onclick="window.location = '/tcg/character/'">Sangonomiya Kokomi</li>
        <li onclick="window.location = '/tcg/character/'">Sayu</li>
        <li onclick="window.location = '/tcg/character/'">Shenhe</li>
        <li onclick="window.location = '/tcg/character/'">Stonehide Lawachurl</li>
        <li onclick="window.location = '/tcg/character/'">Sucrose</li>
        <p>T</p>
        <li onclick="window.location = '/tcg/character/'">Tartaglia</li>
        <li onclick="window.location = '/tcg/character/'">Thoma</li>
        <li onclick="window.location = '/tcg/character/'">Thunder Manifestation</li>
        <li onclick="window.location = '/tcg/character/'">Tighnari</li>
        <p>V</p>
        <li onclick="window.location = '/tcg/character/'">Venti</li>
        <p>W</p>
        <li onclick="window.location = '/tcg/character/'">Wanderer</li>
        <p>X</p>
        <li onclick="window.location = '/tcg/character/'">Xiangling</li>
        <li onclick="window.location = '/tcg/character/'">Xiao</li>
        <li onclick="window.location = '/tcg/character/'">Xingqiu</li>
        <p>Y</p>
        <li onclick="window.location = '/tcg/character/'">Yae Miko</li>
        <li onclick="window.location = '/tcg/character/'">Yanfei</li>
        <li onclick="window.location = '/tcg/character/'">Yaoyao</li>
        <li onclick="window.location = '/tcg/character/'">Yelan</li>
        <li onclick="window.location = '/tcg/character/'">Yoimiya</li>
        <p>Z</p>
        <li onclick="window.location = '/tcg/character/'">Zhongli</li>
    </ul>
</div>