<app-main-menu>
    <div (click)="showMenu()" class="bar-menu">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" focusable="false"
            style="pointer-events: none; display: inherit; width: 100%; height: 100%;">
            <path d="M21 6H3V5h18v1zm0 5H3v1h18v-1zm0 6H3v1h18v-1z"></path>
        </svg>
    </div>
</app-main-menu>
<div id="menu">
    <div style=" position: relative;width: 100%;height: 80px;">
        <div style="position: fixed; top: 0;min-width: 250px;background-color: #777;height: 80px;">
            <div (click)="showMenu()" class="bar-menu">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" focusable="false"
                    style="pointer-events: none; display: inherit; width: 100%; height: 100%;">
                    <path d="M21 6H3V5h18v1zm0 5H3v1h18v-1zm0 6H3v1h18v-1z"></path>
                </svg>
            </div>
            <div class="page-header">{{nameMenu}}</div>
        </div>
    </div>
    <div id="bar-menu">
        <div
            style="padding: 12px;align-items: center;justify-content: center;text-align: center;background-color: darkgray;width: 100%;margin-top: 12px;">
            <div *ngFor="let file of menu ;let i = index;" (click)="chengeMenu(file.key)"
                style="padding: 8px;border: 1px solid;width: 100%;">{{file.key}}
            </div>
        </div>
    </div>
</div>




<div class="container" style="padding: 25px;margin-top: 0px;padding-bottom: 150px;width: 70%;">
    <div style="display: grid;justify-content: center;">
        <div id="date-now" style="color: #000;"></div>
    </div>
    <div
        style="display: grid;justify-content: center;grid-template-columns: 1fr;position: relative;background-color: white;text-align: center;">
        <app-color-rgb style="position: absolute;width: 100%;height: 100%;z-index: 0;">
        </app-color-rgb>
        <h1 style="background-color: white;mix-blend-mode: screen;">
            เคลื่อนไหวอย่างมีประสิทธิภาพ
        </h1>
        <h1 style="background-color: white;mix-blend-mode: screen;">
            ปล่อยใจไม่ใช่ปล่อยตัว
        </h1>
        <h1 style="background-color: white;mix-blend-mode: screen;">
            มีสมาธิตลอดเวลา
        </h1>
        <h1 style="background-color: white;mix-blend-mode: screen;">
            ความสุขไม่ได้มาจากความยึดติด การยึดติดไม่ได้ทำให้เกิดสุข
        </h1>
        <h1 style="background-color: white;mix-blend-mode: screen;">
            ขายขยะให้คนเก็บขยะ,
            หาทองในกองขยะ
        </h1>
        <h1 style="background-color: white;mix-blend-mode: screen;">
            เล่นเกม ถ้าแพ้ เลิกเล่น (มีกฎการเล่นเป็นของตัวเอง)
        </h1>
    </div>
    <p id="test"></p>
    <!--OutPut-->
    <div style="text-align: center;background-color: #0000002c;" (click)="addMass = true" *ngIf="!addMass">
        <i class="bi-plus-circle" style="font-size: 4rem; color: #000000a4;"></i>
    </div>
    <!--OutPut-->
    <!--Input-->
    <div style="overflow-y: hidden;display: grid;gap: 12px;" *ngIf="addMass">
        <div *ngIf="displayNewFolder">Folder : <input placeholder="เพิ่มข้อความ..." name="manu" [(ngModel)]="file.title"
                id="displayNewFolder">
        </div>
        <textarea rows="1" placeholder="เพิ่มข้อความ..." (input)="auto_grow($event)" name="project_detail"
            [(ngModel)]="file.note"></textarea>
        <div style="display: grid;grid-template-columns: 1fr 1fr 1fr;gap: 12px;">
            <button (click)="displayNewFolder = true;" *ngIf="!displayNewFolder">New Folder</button>
            <input type="submit" (click)="saveFileData()">
            <input type="button" value="Cancel" (click)="addMass = false;displayNewFolder = false;">
        </div>
    </div>
    <!--Input-->
    <div [style]="'height : '+this.maxHeight+'px'">
        <div id="heightMydiv">
            <div class="mydiv" *ngFor="let file of fileUploads ;let i = index;"
                (dblclick)="selectCopyText(file.note,i)">
                <div class="mydivheader">Click here to move</div>
                <div class="box">
                    <pre style="padding-right: 10%;">{{file.note}}</pre>
                </div>
                <div style="display: grid;grid-template-columns: 1fr 1fr;position: relative;">
                    <div style="padding: 8px;" class="copys">Copied !</div>
                    <div class="action">
                        <div style="position: absolute;right: -80px;display: grid;bottom: 0;">
                            <button style="height: 40px;width: 80px;" (click)="alertRemove(file)">
                                ลบข้อมูล
                            </button>
                            <button style="height: 40px;width: 80px;" (click)="selectCopyText(file.note,i)"><span
                                    class="material-symbols-outlined">
                                    content_copy
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--OutPut-->

    <!--div id="heightMydiv">
        <div class="mydiv" *ngFor="let file of fileUploads;let i = index;">
            <div class="mydivheader">Click here to move</div>
            <div class="box"><pre>{{file.note}}</pre>
            </div>
        </div>
    </div-->

</div>



<div id="boxalert" style="display: none;position: fixed;margin-inline: auto;background-color: rgb(255, 255, 255);
    left: 50%;transform: translate(-50%,-50%);top: 40%;
    z-index: 999;width: 40%;height: 50vh;border-radius: 8px;border-inline: solid;overflow: hidden;">

    <div
        style="position: absolute;bottom: 0;right: 0;background-color: rgb(0, 255, 213);width: 100%;height: 20%;border-radius: 0px 0px 8px 8px;z-index: 1;">
        <div style="display: flex;justify-content: end;align-items: center;height: 100%;padding-right: 10%;" id="items">
            <button onclick="document.getElementById('boxalert').style.display='none'">cancel</button>
            <button type="submit">submit</button>
        </div>
    </div>

    <div style="position: absolute;top: 30%;width: 100%;text-align: center;font-size: clamp(1rem, 2.5vw, 2rem);white-space: pre-wrap;"
        id="notify-title">
        คุณต้องการลบข้อมูลหรือไม่
    </div>
</div>