import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-count-live',
  templateUrl: './count-live.component.html',
  styleUrls: ['./count-live.component.css']
})
export class CountLiveComponent implements OnInit {

  constructor() { }
  startDate: any;
  time = "";
  ngOnInit(): void {
    this.startDate = new Date();
    this.countTime();
  }

  countTime() {
    let date_now = new Date();
    let count_date = +date_now.getTime() - +this.startDate.getTime() //+ 86400000;
    let date = Math.floor(count_date / 1000 / 60 / 60 / 24);
    let hours = this.setDate(Math.floor(count_date / 1000 / 60 / 60 % 24));
    let minutes = this.setDate(Math.floor(count_date / 1000 / 60 % 60));
    let second = this.setDate(Math.floor(count_date / 1000 % 60));
    setTimeout(() => {
      this.time = `${hours}:${minutes}:${second}`;
      if (date != 0) {
        this.time = `${date}วัน ` + this.time;
      }
      this.countTime();
    }, 1000)
  }

  setDate(num: number) {
    if (num < 10) {
      return `0${num}`;
    } else {
      return num;
    }
  }

}
