import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//database
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

//service
import { LoginService } from './service/Login/login.service';
import { FnService } from './service/fn/fn.service';
//router
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { EmployeeComponent } from './admin/employee/employee.component';
import { MainComponent } from './admin/main/main.component';
import { MenuComponent } from './menu/menu.component';
import { MainMenuComponent } from './admin/main-menu/main-menu.component';
import { ColorRgbComponent } from './color-rgb/color-rgb.component';
import { ProfileComponent } from './profile/profile.component';
import { CharacterEventComponent } from './TCG/character/character-event/character-event.component';
import { WeaponEventComponent } from './weapon-event/weapon-event.component';
import { TcgMenuComponent } from './TCG/tcg-menu/tcg-menu.component';
import { TcgMainComponent } from './TCG/tcg-main/tcg-main.component';
import { CharacterTcgComponent } from './TCG/character-tcg/character-tcg.component';

import { AbyssHeraldWickedTorrentsTCGComponent } from './TCG/character/abyss-herald-wicked-torrents-tcg/abyss-herald-wicked-torrents-tcg.component';
import { FnIndexComponent } from './fn-project/fn-index/fn-index.component';
import { FnMenuComponent } from './fn-project/fn-menu/fn-menu.component';
import { FnProjectsComponent } from './fn-project/fn-projects/fn-projects.component';
import { WebhookGithubComponent } from './webhook-github/webhook-github.component';
import { CountLiveComponent } from './tiktok-live/count-live/count-live.component';
import { ArtifactLiveComponent } from './tiktok-live/artifact-live/artifact-live.component';
import { EditArtifactLiveComponent } from './tiktok-live/edit-artifact-live/edit-artifact-live.component';
import { DiscordGuildsComponent } from './discord-guilds/discord-guilds.component';
import { DiscordChannelsComponent } from './discord-channels/discord-channels.component';
import { TiktokNotifyComponent } from './tiktok-live/tiktok-notify/tiktok-notify.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    EmployeeComponent,
    MainComponent,
    MenuComponent,
    MainMenuComponent,
    ColorRgbComponent,
    ProfileComponent,
    CharacterEventComponent,
    WeaponEventComponent,
    TcgMenuComponent,
    TcgMainComponent,
    CharacterTcgComponent,
    AbyssHeraldWickedTorrentsTCGComponent,
    FnIndexComponent,
    FnMenuComponent,
    FnProjectsComponent,
    WebhookGithubComponent,
    CountLiveComponent,
    ArtifactLiveComponent,
    EditArtifactLiveComponent,
    DiscordGuildsComponent,
    DiscordChannelsComponent,
    TiktokNotifyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: 'home', component: HomeComponent },

      { path: 'profile', component: ProfileComponent },
      { path: 'color', component: ColorRgbComponent },

      { path: 'character_event', component: CharacterEventComponent },

      { path: 'tcg', component: TcgMainComponent },
      { path: 'tcg/character', component: CharacterTcgComponent },

      { path: 'tcg/character/:charactor_name', component: AbyssHeraldWickedTorrentsTCGComponent },

      { path: 'puttipong/admin/login', component: LoginComponent, canActivate: [LoginService] },
      { path: 'employee', component: EmployeeComponent, canActivate: [LoginService] },
      { path: 'main', component: MainComponent, canActivate: [LoginService] },
      { path: 'fn/index', component: FnIndexComponent, canActivate: [LoginService] },
      { path: 'fn/projects', component: FnProjectsComponent, canActivate: [LoginService, FnService] },

      { path: 'tiktok/edit-artifact', component: EditArtifactLiveComponent, canActivate: [LoginService] },

      { path: ':guildId/:channelId/github', component: WebhookGithubComponent },

      { path: 'tiktok/count-live', component: CountLiveComponent },
      { path: 'tiktok/artifact', component: ArtifactLiveComponent },
      { path: 'tiktok/notify', component: TiktokNotifyComponent },

      { path: 'discord/guilds', component: DiscordGuildsComponent },

      { path: '**', redirectTo: 'home', pathMatch: 'full' }
    ])
  ],
  providers: [LoginService, AngularFireModule, AppRoutingModule, AngularFireDatabaseModule, HttpClientModule, AngularFireAuthModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
