<div style="width: 100vw;height: auto;position: relative;">
    <div class="list">
        <app-artifact-live></app-artifact-live>
        <div class="note">
            <input type="text" name="uid" placeholder="UID" [(ngModel)]="file.uid">
            <input type="text" name="note" placeholder="Note" [(ngModel)]="file.note">
            <button style="background-color: 15A671;border-radius: 25px;" (click)="saveFileData()">
                Submit
            </button>
        </div>
    </div>
</div>