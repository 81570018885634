<app-main-menu></app-main-menu>
<div *ngFor="let data of list">
    {{data.username}} : {{data.password}}
</div>
<div *ngFor="let test of list_login">
    {{cute(test.key)}}
    {{cute(test.id)}}
</div>
<input [(ngModel)]="file.username">
<input [(ngModel)]="file.password">
<button (click)="submit()">sss</button>