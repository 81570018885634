import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { map } from 'rxjs';
import { Login } from '../../model/login'

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  list: any;
  list_login: any;
  file: Login = new Login();
  pathEmployee = '/employee'
  logined = '/login/file_login';

  constructor(private db: AngularFireDatabase) { }

  ngOnInit(): void {
    this.getFileUploads(this.pathEmployee).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(result => {
      this.list = result;
    });

    this.getFileUploads(this.logined).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(result => {
      this.list_login = result;
    });

  }
  getFileUploads(path: string): AngularFireList<Login> {
    return this.db.list(path)
  }


  cute(item: string) {
    let txt = item.replace(/-/g, '');
    return txt;
  }
  submit() {
    if (this.file.username.trim() != '' && this.file.password.trim() != '') {
      this.db.list(this.pathEmployee).push(this.file);
    }
  }
}
