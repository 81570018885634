<!--app-main-menu></app-main-menu>

<div style="display: grid;grid-template-columns: repeat(6,1fr);">
    <div style="height:100px;background-color: rgb(255, 0, 0);"></div>
    <div style="height:100px;background-color: rgb(0, 255, 0);"></div>
    <div style="height:100px;background-color: rgb(0, 0, 255);"></div>
    <div style="height:100px;background-color: rgb(255, 255,0);"></div>
    <div style="height:100px;background-color: rgb(255, 0,255);"></div>
    <div style="height:100px;background-color: rgb(0, 255,255);"></div>
    <div style="height:100px;background-color: rgb(255, 255,255);"></div>
</div-->
<div id="main-rgb">
    <div id="rgb">
        <ng-content></ng-content>
    </div>
</div>

<label id="txt"></label>
<style>
    #rgb {
        /*background-image: linear-gradient(to right, rgb(0, 255, 255), rgb(255, 0, 0));*/
        height: 100%;
    }
</style>