export class Message {
    key!: string;
    name!: string;
    url!: string;
  
    sort: number;
    note: string;

  
    constructor(sort:number,note: string) {
      this.sort = sort;
      this.note = note;
    }

}