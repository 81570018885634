import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../service/Login/login.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  id = localStorage.getItem('id');
  username = localStorage.getItem('username');
  user: any | undefined;
  responseHeaders: string | undefined;
  constructor(private router: Router, private em: LoginService, private auth: AngularFireAuth) {
  }

  ngOnInit(): void {
    this.auth.user.subscribe(userInfo => {
      this.user = userInfo?.uid;
    })
  }

  login() {

  }

  logout() {
    this.em.logout();
  }

  test() {
    alert(this.user)
    console.log(this.user);


  }


}
