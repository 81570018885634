import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements CanActivate {

  pathEmployee = '/employee'
  logined = '/login/file_login';
  users: any | undefined;
  constructor(private db: AngularFireDatabase, private router: Router, private auth: AngularFireAuth) {
    auth.user.subscribe(item => {
      this.users = item;
      //console.log(item?.email);
      //console.log(this.users);
    })
  }

  SignUp(email: any, password: any) {
    return this.auth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        window.alert('You have been successfully registered!');
        console.log(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Sign in with email/password
  SignIn(email: any, password: any) {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        let id = localStorage.getItem('tokenId');
        if (id) {
          this.db.list(this.logined).update(id, { id: true })
        } else {
          let key = this.db.list(this.logined).push({ id: true }).key;
          if (key) localStorage.setItem('tokenId', key)
        }
        //this.users = result;
      }).catch((error) => {
        window.alert(error.message);
      });
  }

  logout() {
    this.auth.signOut()
      .then(() => {
        let id = localStorage.getItem('tokenId');
        if (id) {
          this.db.list(this.logined).update(id, { id: false })
        }
        this.router.navigate(['home']);
        // Logout successful
      })
      .catch((error) => {
        // An error occurred
      });
  }

  isLogined(): Observable<boolean> {
    return this.auth.authState.pipe(
      take(1),
      map(user => {
        if (user) {
          // User is authenticated, allow access to the route
          return true;
        } else {
          // User is not authenticated, redirect to the login page
          return false;
        }
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.auth.authState.pipe(
      take(1),
      map(user => {
        if (route.url.toString() == 'puttipong,admin,login') {
          if (user) {
            this.router.navigate(['puttipong/admin/login']);
            return false;
          } else {
            return true;
          }
        } else {
          if (user) {
            // User is authenticated, allow access to the route
            return true;
          } else {
            // User is not authenticated, redirect to the login page
            this.router.navigate(['home']);
            return false;
          }
        }
      })
    );
  }
}
