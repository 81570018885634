import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-color-rgb',
  templateUrl: './color-rgb.component.html',
  styleUrls: ['./color-rgb.component.css']
})
export class ColorRgbComponent implements OnInit {

  @Input('type') type: any;
  elementRef: ElementRef
  constructor(@Inject(ElementRef) elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    if (this.type == 'wave') {
      this.wave();
    } else if (this.type == 'cycle') {
      this.cycle();
    } else {
      this.wave();
    }

  }

  colors = [
    { r: 255, g: 0, b: 0 },
    { r: 0, g: 255, b: 0 },
    { r: 0, g: 0, b: 255 },
    { r: 255, g: 255, b: 0 },
    { r: 255, g: 0, b: 255 },
    { r: 0, g: 255, b: 255 },
    { r: 255, g: 255, b: 255 }
  ]
  i = 0;
  or = 255;
  og = 0;
  ob = 0;
  nr = 0;
  ng = 255;
  nb = 0;

  check = false;
  ngAfterViewChecked() {
    if (!this.check) {
      this.check = true;
    }
  }

  changeRGB(n: number, o: number) {
    let m = 5;
    if (n > o) {
      return (n - m);
    } else if (n < o) {
      return (n + m);
    } else {
      return n;
    }
  }
  wave() {
    setTimeout(
      () => {
        let rgb = this.elementRef.nativeElement.querySelector('#rgb');
        this.wave();
        let n = this.i + 1;
        if (this.i == (this.colors.length - 1)) {
          n = 0;
        }
        let j = n + 1;
        if (n == (this.colors.length - 1)) {
          j = 0;
        }
        if (this.or != this.colors[n].r || this.og != this.colors[n].g || this.ob != this.colors[n].b
          || this.nr != this.colors[j].r || this.ng != this.colors[j].g || this.nb != this.colors[j].b) {
          this.or = this.changeRGB(this.or, this.colors[n].r)
          this.og = this.changeRGB(this.og, this.colors[n].g)
          this.ob = this.changeRGB(this.ob, this.colors[n].b)

          this.nr = this.changeRGB(this.nr, this.colors[j].r)
          this.ng = this.changeRGB(this.ng, this.colors[j].g)
          this.nb = this.changeRGB(this.nb, this.colors[j].b)
        } else {
          this.i += 1;
        }
        //document.getElementById('txt').innerText = `${this.or},${this.og},${this.ob} : ${this.nr},${this.ng},${this.nb}`;
        this.elementRef.nativeElement.querySelector('#main-rgb').style.backgroundColor = 'rgb(0, 0, 0, 0)';
        rgb.style.backgroundImage = `linear-gradient(to right, rgb(${this.or}, ${this.og}, ${this.ob}), rgb(${this.nr}, ${this.ng}, ${this.nb}))`;
        if (this.i == (this.colors.length - 1)) {
          this.i = 0;
        }
      }
      , 50)
  }

  action = false;

  cycle() {
    if (this.action == false) {
      this.elementRef.nativeElement.querySelector('#main-rgb').style.backgroundColor = 'rgb(0, 0, 0)';
      let rgb = this.elementRef.nativeElement.querySelector('#rgb');
      rgb.style.animation = 'cycle 4s infinite';
      rgb.style.backgroundColor = `rgb(${this.colors[this.i].r},${this.colors[this.i].g},${this.colors[this.i].b})`
      this.i++;
      if (this.i == (this.colors.length - 1)) {
        this.i = 0;
      }
      this.action = true;
    }

    setTimeout(
      () => {
        let rgb = this.elementRef.nativeElement.querySelector('#rgb');
        rgb.style.backgroundColor = `rgb(${this.colors[this.i].r},${this.colors[this.i].g},${this.colors[this.i].b})`
        this.i++;
        if (this.i == (this.colors.length - 1)) {
          this.i = 0;
        }
        this.cycle();
      }
      , 4000)
  }

}
