import { Component, ElementRef, Inject, HostListener, Input, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Login } from '../model/login';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  elementRef: ElementRef;
  private basePath = '/test';
  //@Input() fileUpload: TCG | undefined;

  fileUploads: any[] | undefined;
  list: any;

  constructor(private http: HttpClient, private db: AngularFireDatabase, private storage: AngularFireStorage, @Inject(ElementRef) elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {
    this.elementRef = elementRef;
  }
  ngOnInit(): void {
    /*
    this.getFileUploads('/employee').snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(result => {
      this.list = result;
    });*/
  }

  getFileUploads(path: string): AngularFireList<Login> {
    return this.db.list(path)
  }

  test() {
    this.db.list(this.basePath).push({ name: 'test server' });
  }
}
