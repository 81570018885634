import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/Login/login.service';

@Component({
  selector: 'app-tcg-menu',
  templateUrl: './tcg-menu.component.html',
  styleUrls: ['./tcg-menu.component.css']
})
export class TcgMenuComponent implements OnInit {

  logined = '/login/file_login';
  user: any | undefined;
  constructor(private db: AngularFireDatabase, private router: Router, private em: LoginService, private auth: AngularFireAuth) {

  }

  ngOnInit(): void {
    this.auth.user.subscribe(userInfo => {
      this.user = userInfo?.uid;
    })
  }

  logout() {
    this.em.logout();
    this.router.navigate(['/']);
  }

}
