<div *ngFor="let item of guildData; let index = index">
    <div (click)="selGuild = item.id">
        <img src="{{item.iconURL}}" alt="">
        {{ item.id }}
        {{ item.name }}
    </div>
    <div *ngIf="selGuild == item.id">
        <div *ngFor="let channel of item.channels">
            <div *ngIf="channel.type == 0"><i class="bi bi-hash"></i> {{channel.name}}</div>
            <div *ngIf="channel.type == 2"><i class="bi bi-volume-up-fill"></i> {{channel.name}}</div>
        </div>
    </div>
</div>