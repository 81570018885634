import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '../model/login';
import { LoginService } from '../service/Login/login.service'
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  file: Login = new Login();
  logined = '/login/file_login';

  elementRef: ElementRef;
  list: any[] | undefined;
  constructor(private db: AngularFireDatabase, private router: Router, @Inject(ElementRef) elementRef: ElementRef, private em: LoginService) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {/*
    this.getFileUploads('/employee').snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(result => {
      this.list = result;
    });*/
  }

  getFileUploads(path: string): AngularFireList<Login> {
    return this.db.list(path)
  }

  login() {
    this.em.SignIn(this.file.username, this.file.password).finally(() => {
      this.router.navigate(['main']);
    });
  }

  cute(item: string) {
    let txt = item.replace(/-/g, '');
    return txt;
  }
}
