<app-tcg-menu></app-tcg-menu>
<style>
    .main-tcg {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .card-tcg {
        display: grid;
        grid-template-columns: 40% 60%;
    }

    .card-tcg>div:first-child {
        margin: 24px;
        padding: 0;
    }

    .card-tcg>div {
        margin: 24px 0;
        padding: 12px;
    }

    .card-tcg img {
        width: 100%;
        -webkit-user-drag: none;
    }

    .img-tcg {
        position: relative;
        user-select: none;
        display: grid;
        justify-content: center;
        align-items: center;
        height: fit-content;
        max-width: 430px;
    }

    .detail-tcg {
        display: grid;
        gap: 12px;
    }

    .detail-tcg>div {
        position: relative;
        background-color: #eee;
        border-radius: 12px;
        padding: 12px;
        height: fit-content;
    }

    .detail-tcg .main-point {
        user-select: none;
        position: absolute;
        height: 5vh;
        display: flex;
        right: 0;
        top: 0;
    }

    .detail-tcg div img {
        height: 100%;
        width: 100%;
    }

    .detail-tcg div {
        position: relative;
    }

    .detail-tcg div .num {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 20px;
        filter: drop-shadow(-2px 2px 2px #000);
        text-align: center;
    }

    .img-tcg .main-point {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .main-point .point:nth-child(1) {
        position: absolute;
        right: -12%;
        top: -5%;
        width: 30%;
    }

    .main-point .point:nth-child(2) {
        position: absolute;
        right: -14%;
        top: 12.5%;
        width: 30%;
    }

    .main-point .point:nth-child(3) {
        position: absolute;
        right: -14%;
        top: 29%;
        width: 30%;
    }

    .main-point .point:nth-child(4) {
        position: absolute;
        right: -14%;
        top: 46%;
        width: 30%;
    }

    .point .num {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: calc(1.0rem + 2vw);
        filter: drop-shadow(-2px 2px 2px #000);
        text-align: center;
    }
</style>
<div style="padding: 24px;">
    <h3>
        Abyss Herald - Wicked Torrents
    </h3>
</div>
<div class="main-tcg">
    <div class="card-tcg">
        <div class="img-tcg">
            <img src="../../../../assets/tcg/character/Abyss Herald Wicked Torrents/Abyss Herald Wicked Torrents.png"
                style="width: 100%;">
            <img src="../../../../assets/tcg/icon/tcg_border.png" style="position: absolute;">
            <div class="main-point">
                <div class="point">
                    <img src=" ../../../../assets/tcg/icon/tcg_character_hp.png">
                    <p class="num">
                        6
                    </p>
                </div>
                <img src=" ../../../../assets/tcg/icon/tcg_action_recharge.png" class="point">
                <img src="../../../../assets/tcg/icon/tcg_action_recharge.png" class="point">
            </div>
        </div>
        <div class="detail-tcg">
            <div>
                <h5>Rippling Slash</h5>
                <p>โจมตีปกติ</p>
                <p>สร้าง <u>ความเสียหายกายภาพ</u> 2 หน่วย</p>
                <div class="main-point">
                    <div>
                        <img src="../../../../assets/tcg/icon/tcg_action_point_hydro.png">
                        <p class="num">
                            1
                        </p>
                    </div>
                    <div>
                        <img src="../../../../assets/tcg/icon/tcg_action_point_common_black.png">
                        <p class="num">
                            2
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <h5>Vortex Edge</h5>
                <p>สกิลธาตุ</p>
                <p>
                    สร้าง <u style="color: blue;">ความเสียหายน้ำ</u> 2 หน่วย
                    จากนั้นจะ<u>เตรียมสกิล : Rippling_Blades</u>
                    <br><br>
                    สกิลธาตุ : (Rippling Blades) <br>
                    สร้าง <u style="color: blue;">ความเสียหายน้ำ</u> 1 หน่วย
                </p>
                <div class="main-point">
                    <div>
                        <img src="../../../../assets/tcg/icon/tcg_action_point_hydro.png">
                        <p class="num">
                            3
                        </p>
                    </div>
                </div>
            </div>

            <div>
                <h5>Torrential Shock</h5>
                <p>ท่าไม้ตาย</p>
                <p>สร้าง<u style="color: blue;">ความเสียหายน้ำ</u> 3 หน่วย และทำให้เกิด
                    <u>Curse_of_the_Undercurrent</u>
                    ในสนามของฝ่ายตรงข้าม
                    <br><br>
                    เมื่อตัวละครใช้สกิลธาตุ : (Curse of the Undercurrent) <br>
                    เมื่อตัวละครใช้ สกิลธาตุ หรือ ท่าไม้ตาย ใช้ ลูกเต๋า เพิ่มเติม 1 ลูก
                    <u>จำนวนครั้งที่ใช้ได้</u>: 2
                </p>
                <div class="main-point">
                    <div>
                        <img src="../../../../assets/tcg/icon/tcg_action_point_hydro.png">
                        <p class="num">
                            3
                        </p>
                    </div>
                    <div>
                        <img src="../../../../assets/tcg/icon/tcg_action_recharge.png">
                        <p class="num">
                            2
                        </p>
                    </div>
                </div>
            </div>

            <div>
                <h5>สกิลติดตัว</h5>
                <p>
                    เมื่อเริ่มการต่อสู้ ในตอนแรกเริ่มจะได้รับ : <u>Wetery_Rebirth</u><br><br>
                    เมื่อตัวละครนี้ถูกกำจัด (Wetery Rebirth) <br>
                    หลีกเลี่ยงการพ่ายแพ้ และ รักษาตัวละนี้ 4 หน่วย
                    <br>หลักจากเกิดเอฟเฟกนี้<br>
                    <u>ความเสียหายกายภาพ</u> ที่ตัวละครสร้างจจะเปลี่ยนเป็น
                    <u style="color: blue;">ความเสียหายน้ำ</u>
                    และ <u style="color: blue;">ความเสียหายน้ำ</u> + 1
                </p>
            </div>

        </div>
    </div>
    <div class="card-tcg">
        <div class="img-tcg">
            <img src="../../../../assets/tcg/icon/tcg_border.png" style="position: absolute;">
            <img
                src="../../../../assets/tcg/character/Abyss Herald Wicked Torrents/cf40b16459f2ce28f689365a4fc30b99_2137961059537548930.png">
            <div class="main-point">
                <div class="point">
                    <img src=" ../../../../assets/tcg/icon/tcg_action_point_hydro.png">
                    <p class="num">
                        1
                    </p>
                </div>
            </div>
        </div>
        <div class="detail-tcg">
            <div>
                <h5>
                    Surging Undercurrent
                </h5>
                <p>
                    เมื่อเข้าสู้สนาม: หาก <u>Abyss Herald - Wicked Torrents</u><br>
                    เคยทำให้เกิด <u>Wetery_Rebirth</u> จะสร้าง <u>Curse_of_the_Undercurrent</u>
                    ในสนามของฝ่ายตรงข้าม
                </p>
                <hr>
                <p>
                    สกิลติดตัว : (Wetery Rebirth)
                    <br>
                    หลีกเลี่ยงการพ่ายแพ้ และ รักษาตัวละนี้ 4 หน่วย <br>หลักจากเกิดเอฟเฟกนี้<br>
                    <u>ความเสียหายกายภาพ</u> ที่ตัวละครสร้างจจะเปลี่ยนเป็น
                    <u style="color: blue;">ความเสียหายน้ำ</u>
                    และ <u style="color: blue;">ความเสียหายน้ำ</u> + 1
                </p>
            </div>
        </div>
    </div>
</div>