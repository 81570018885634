<div id="table" style="position: relative;padding: 32px;">
    <div class="title">
        Artifact
    </div>
    <div id="board">
        <div id="bg"></div>
        <div id="main">
            <div id="items">
                <div *ngFor="let item of fileUploads" class="table">
                    <div class=" list" [ngClass]="{'skip' : (item.status == 2) }">
                        <div>
                            <p>{{item.uid}}</p>
                        </div>
                        <div>
                            <p>{{item.note}}</p>
                        </div>
                    </div>
                    <div class="list-bt" *ngIf="user">
                        <div *ngIf="(item.status == 1)">
                            <button (click)="skipArtifact(item.key)">ข้าม</button>
                            <button (click)="acceptArtifact(item.key)">ตรวจแล้ว</button>
                        </div>
                        <div *ngIf="(item.status == 2)">
                            <button (click)="playArtifact(item.key)">กลับมาแล้ว</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style="position: absolute;bottom: 0;left: 50%;transform: translate(-50%,0);padding: 25px;">
                <div style="display: grid;align-items: center;justify-content: center;padding: 12px;">
                    <img src="../../../assets/img/underline-genshin.png">
                </div>
                <div style="display: grid;align-items: center;justify-content: center;">
                    <img src="../../../assets/img/genshin_logo.png">
                </div>
            </div>
        </div>
    </div>
</div>