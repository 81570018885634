import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discord-channels',
  templateUrl: './discord-channels.component.html',
  styleUrls: ['./discord-channels.component.css']
})
export class DiscordChannelsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
