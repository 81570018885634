<style>
    .main {
        padding: 0 10%;
        background-color: black;
    }

    .col2 {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-image: linear-gradient(to top, #fff, #000);
        border-left: 5px solid goldenrod;
        margin-bottom: 2px;
    }

    .col2 img {
        width: 100%;
    }

    .col2:first-child {
        border-left: 5px solid rgb(0, 255, 255);
    }

    .text-content {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60%;
        background-image: linear-gradient(to top, #000, #00000000);
        color: #fff;
        font-size: larger;

    }

    .text-content div {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 5px;
        display: grid;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
</style>
<div class="main">
    <div class="col2">
        <img src="../../assets/character_event/57_1.png">
        <img src="../../assets/character_event/57_2.png">
        <div class="text-content">
            <div>
                <span>4.6 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/56_1.png">
        <img src="../../assets/character_event/56_2.png">
        <div class="text-content">
            <div>
                <span>4.6 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/55_1.png">
        <img src="../../assets/character_event/55_2.png">
        <div class="text-content">
            <div>
                <span>4.5 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/54_1.png">
        <img src="../../assets/character_event/54_2.png">
        <div class="text-content">
            <div>
                <span>4.5 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/53_1.png">
        <img src="../../assets/character_event/53_2.png">
        <div class="text-content">
            <div>
                <span>4.4 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/52_1.png">
        <img src="../../assets/character_event/52_2.png">
        <div class="text-content">
            <div>
                <span>4.4 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/51_1.png">
        <img src="../../assets/character_event/51_2.png">
        <div class="text-content">
            <div>
                <span>4.3 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/50_1.png">
        <img src="../../assets/character_event/50_2.png">
        <div class="text-content">
            <div>
                <span>4.3 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/49_1.png">
        <img src="../../assets/character_event/49_2.png">
        <div class="text-content">
            <div>
                <span>4.2 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/48_1.png">
        <img src="../../assets/character_event/48_2.png">
        <div class="text-content">
            <div>
                <span>4.2 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/47_1.png">
        <img src="../../assets/character_event/47_2.png">
        <div class="text-content">
            <div>
                <span>4.1 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/46_1.png">
        <img src="../../assets/character_event/46_2.png">
        <div class="text-content">
            <div>
                <span>4.1 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/45_1.png">
        <img src="../../assets/character_event/45_2.png">
        <div class="text-content">
            <div>
                <span>4.0 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/44_1.png">
        <img src="../../assets/character_event/44_2.png">
        <div class="text-content">
            <div>
                <span>4.0 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/43_1.png">
        <img src="../../assets/character_event/43_2.png">
        <div class="text-content">
            <div>
                <span>3.8 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/42_1.png">
        <img src="../../assets/character_event/42_2.png">
        <div class="text-content">
            <div>
                <span>3.8 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/41_1.png">
        <img src="../../assets/character_event/41_2.png">
        <div class="text-content">
            <div>
                <span>3.7 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/40_1.png">
        <img src="../../assets/character_event/40_2.png">
        <div class="text-content">
            <div>
                <span>3.7 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/39_1.png">
        <img src="../../assets/character_event/39_2.png">
        <div class="text-content">
            <div>
                <span>3.6 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/38_1.png">
        <img src="../../assets/character_event/38_2.png">
        <div class="text-content">
            <div>
                <span>3.6 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/37_1.png">
        <img src="../../assets/character_event/37_2.png">
        <div class="text-content">
            <div>
                <span>3.5 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/36_1.png">
        <img src="../../assets/character_event/36_2.png">
        <div class="text-content">
            <div>
                <span>3.5 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/35_1.png">
        <img src="../../assets/character_event/35_2.png">
        <div class="text-content">
            <div>
                <span>3.4 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/34_1.png">
        <img src="../../assets/character_event/34_2.png">
        <div class="text-content">
            <div>
                <span>3.4 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/33_1.png">
        <img src="../../assets/character_event/33_2.png">
        <div class="text-content">
            <div>
                <span>3.3 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/32_1.png">
        <img src="../../assets/character_event/32_2.png">
        <div class="text-content">
            <div>
                <span>3.3 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/31_1.png">
        <img src="../../assets/character_event/31_2.png">
        <div class="text-content">
            <div>
                <span>3.2 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/30_1.png">
        <img src="../../assets/character_event/30_2.png">
        <div class="text-content">
            <div>
                <span>3.2 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/29_1.png">
        <img src="../../assets/character_event/29_2.png">
        <div class="text-content">
            <div>
                <span>3.1 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/28_1.png">
        <img src="../../assets/character_event/28_2.png">
        <div class="text-content">
            <div>
                <span>3.1 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/27_1.png">
        <img src="../../assets/character_event/27_2.png">
        <div class="text-content">
            <div>
                <span>3.0 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/26_1.png">
        <img src="../../assets/character_event/26_2.png">
        <div class="text-content">
            <div>
                <span>3.0 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/25.png">
        <div class="text-content">
            <div>
                <span>2.8 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/24_1.png">
        <img src="../../assets/character_event/24_2.png">
        <div class="text-content">
            <div>
                <span>2.8 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/23.png">
        <div class="text-content">
            <div>
                <span>2.7 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/22_1.png">
        <img src="../../assets/character_event/22_2.png">
        <div class="text-content">
            <div>
                <span>2.7 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/21.png">
        <div class="text-content">
            <div>
                <span>2.6 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/20_1.png">
        <img src="../../assets/character_event/20_2.png">
        <div class="text-content">
            <div>
                <span>2.6 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/19_1.png">
        <img src="../../assets/character_event/19_2.png">
        <div class="text-content">
            <div>
                <span>2.5 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/18.png">
        <div class="text-content">
            <div>
                <span>2.5 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/17_1.png">
        <img src="../../assets/character_event/17_2.png">
        <div class="text-content">
            <div>
                <span>2.4 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/16_1.png">
        <img src="../../assets/character_event/16_2.png">
        <div class="text-content">
            <div>
                <span>2.4 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/15.png">
        <div class="text-content">
            <div>
                <span>2.3 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/14_1.png">
        <img src="../../assets/character_event/14_2.png">
        <div class="text-content">
            <div>
                <span>2.3 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/13.png">
        <div class="text-content">
            <div>
                <span>2.2 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/12.png">
        <div class="text-content">
            <div>
                <span>2.2 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/11.png">
        <div class="text-content">
            <div>
                <span>2.1 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/10.png">
        <div class="text-content">
            <div>
                <span>2.1 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/9.png">
        <div class="text-content">
            <div>
                <span>2.0 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/8.png">
        <div class="text-content">
            <div>
                <span><span style="color: #36a9c6;">ตำหนักกระเรียนขาว</span> 2.0 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/7.png">
        <div class="text-content">
            <div>
                <span>ไม้ร่วงตามสายลม 1.6 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/6.png">
        <div class="text-content">
            <div>
                <span>ก้าวย่างพร่างพราย 1.6 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/5.png">
        <div class="text-content">
            <div>
                <span>ประกายแห่งเกลียวคลื่น 1.5 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/4.png">
        <div class="text-content">
            <div>
                <span>ขุนเขาและเมืองใหญ่ 1.5 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/3.png">
        <div class="text-content">
            <div>
                <span>บอกลาแดนเหนือ 1.4 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/2.png">
        <div class="text-content">
            <div>
                <span>ถ้วยแห่งบทกวี 1.4 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/1.png">
        <div class="text-content">
            <div>
                <span>ช่วงเวลาแห่งการผลิบาล 1.3 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/22_2.png">
        <div class="text-content">
            <div>
                <span>คำเชิญอันสว่างไสว 1.3 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/1_2_2.jpg">
        <div class="text-content">
            <div>
                <span>ม่านแห่งเมฆา 1.2 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/1_2_1.jpg">
        <div class="text-content">
            <div>
                <span>ความลับในหวงลึก</span>
                <span>1.2 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/1_1_2.jpg">
        <div class="text-content">
            <div>
                <span>บอกลาแดนเหนือ</span>
                <span>1.1 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/4.png">
        <div class="text-content">
            <div>
                <span><span style="color: #face02;">ขุนเขา</span>และเมืองใหญ่</span>
                <span>1.1 ครึ่งแรก</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/1_0_2.jpg">
        <div class="text-content">
            <div>
                <span>ก้าวย่าง<span style="color: #df4121;">พร่างพราย</span></span>
                <span>1.0 ครึ่งหลัง</span>
            </div>
        </div>
    </div>
    <div class="col2">
        <img src="../../assets/character_event/2.png">
        <div class="text-content">
            <div>
                <span><span style="color: #067372;">ถ้วย</span>แห่งบทกวี</span>
                <span> 1.0 ครึ่งแรก</span>
            </div>
        </div>
    </div>
</div>